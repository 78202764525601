/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  colors, weight,
} from 'globalStyle';
import styled, { css } from 'styled-components';
import { Link, useRoute } from 'wouter';
import { basicButton } from './styled';

import { colorType } from './type';

export const Ghost = ({
  onColorType, noActive, href = '/', disabled, children, isSubmit, ...rest
}) => {
  const [isActive] = useRoute(href);
  if (onColorType === colorType.BLUE) {
    return (isSubmit || disabled)
      ? (
        <BlueSubmit
          disabled={disabled}
          {...rest}
        >
          {children}
        </BlueSubmit>
      ) : (
        <Link href={href}>
          <BlueA {...rest}>{children}</BlueA>
        </Link>
      );
  }

  if (onColorType === colorType.GRAY) {
    return (isSubmit || disabled)
      ? (
        <GraySubmit
          disabled={disabled}
          {...rest}
        >
          {children}
        </GraySubmit>
      ) : (
        <Link href={href}>
          <GrayA {...rest}>{children}</GrayA>
        </Link>
      );
  }

  return (isSubmit || disabled)
    ? (
      <WhiteSubmit
        disabled={disabled}
        {...rest}
      >
        {children}

      </WhiteSubmit>
    ) : (
      <Link href={href}>
        <WhiteA
          isActive={noActive ? false : isActive}
          {...rest}
        >
          {children}

        </WhiteA>
      </Link>
    );
};

const button = css`

    border: none;
    padding: 0;
    background-color: transparent;
    
    font-weight: ${weight.REGULAR};
    font-size: 16px;
    
    ${(props) => props.italic && css`
        font-style: italic;
    `}

    ${(props) => props.underline && css`
        text-decoration: underline;
    `}
`;

const white = css`
    ${button}
    color: ${colors.GRAY_2};

    ${(props) => props.isActive && css`
      color: ${colors.primary};
    `}

    &:hover:not([disabled]){
        color: ${colors.primary};
    }
    ${basicButton}
`;

const WhiteA = styled.a`
    ${white}
`;

const WhiteSubmit = styled.button`
    ${white}
`;

const blue = css`
    ${button}
    color: ${colors.WHITE};
    &:hover:not([disabled]){
        text-decoration: underline;
    }
    ${basicButton}
`;

export const BlueA = styled.a`
    ${blue}
`;

export const BlueSubmit = styled.button`
    ${blue}
`;

const gray = css`
    ${button}
    color: ${colors.WHITE};
    ${basicButton}
`;

export const GrayA = styled.a`
    ${gray}
`;

const GraySubmit = styled.button`
    ${gray}
`;
