/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, signOut, updateState } from 'store/auth';
import { useMountedState } from 'react-use';
import { deleteSessionUser } from 'hooks';
import { removeNotifications } from 'store/notification';
import { removeUserInformation } from 'store/userInfo';
import { removeInvestments } from 'store/investments';
import { removeFavoriteAccounts } from 'store/favoriteAccounts';
import { removeBonds } from 'store/bonds';
import { removeInvestors } from 'store/investors';
import { removeProjectsCompany } from 'store/projectsCompany';

export const useUser = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const isLogin = useSelector((store) => store.auth.isLogin);
  const type = useSelector((store) => store.auth.userType);
  const user = useSelector((store) => store.auth.user);

  const login = useCallback(({ data, userType }) => {
    if (isMounted) dispatch(signIn({ user: data, userType }));
  }, []);

  const logout = useCallback(() => {
    if (isMounted) {
      dispatch(removeNotifications());
      dispatch(removeUserInformation());
      dispatch(removeInvestments());
      dispatch(removeFavoriteAccounts());
      dispatch(removeBonds());
      dispatch(removeInvestors());
      dispatch(removeProjectsCompany());
      dispatch(signOut());
      deleteSessionUser();
    }
  }, [user?.token]);

  const update = useCallback(({ key, value }) => {
    dispatch(updateState({ key, value }));
  }, [dispatch]);

  return {
    isLogin,
    type,
    user,
    login,
    logout,
    update,
  };
};
