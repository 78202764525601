/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'wouter';
import { Div, colors } from 'globalStyle';
import styled, { css } from 'styled-components';
import { Loading } from 'components/Loading';
import { basicButton, anatomy } from './styled';

export const hollowColorType = {
  GRAY: 'GRAY',
  YELLOW: 'YELLOW',
  BLUE: 'BLUE',
  WHITE: 'WHITE',
  WhiteBorder: 'WhiteBorder',
  primary: 'primary',
  soyYo: 'soyYo',
  primaryBorder: 'primaryBorder',
  error: 'error',
  fullWhite: 'fullWhite',
  transparent: 'transparent',
  whiteBorderError: 'whiteBorderError',
  tertiaryBorder: 'tertiaryBorder',
};

export const Hollow = ({
  href = '/',
  onColorType,
  isLoading,
  disabled,
  children,
  isSubmit,
  width,
  ...rest
}) => {
  if (isLoading) {
    return (
      <Div isCenter width={width} {...rest}>
        <Loading />
      </Div>
    );
  }
  if (onColorType === hollowColorType.GRAY) {
    return isSubmit || disabled ? (
      <GraySubmit disabled={disabled} {...rest} width={width}>
        {children}
      </GraySubmit>
    ) : (
      <Link href={href} {...rest}>
        <GrayA {...rest} width={width}>
          {children}
        </GrayA>
      </Link>
    );
  }

  if (onColorType === hollowColorType.YELLOW) {
    return isSubmit || disabled ? (
      <YellowSubmit disabled={disabled} {...rest} width={width}>
        {children}
      </YellowSubmit>
    ) : (
      <Link href={href} {...rest}>
        <YellowA {...rest} width={width}>
          {children}
        </YellowA>
      </Link>
    );
  }

  if (onColorType === hollowColorType.BLUE) {
    return isSubmit || disabled ? (
      <BlueSubmit disabled={disabled} {...rest} width={width}>
        {children}
      </BlueSubmit>
    ) : (
      <Link href={href} {...rest}>
        <BlueA {...rest} width={width}>
          {children}
        </BlueA>
      </Link>
    );
  }

  if (onColorType === hollowColorType.primary) {
    return isSubmit || disabled ? (
      <PrimarySubmit disabled={disabled} {...rest} width={width}>
        {children}
      </PrimarySubmit>
    ) : (
      <Link href={href} {...rest}>
        <PrimaryA {...rest} width={width}>
          {children}
        </PrimaryA>
      </Link>
    );
  }

  if (onColorType === hollowColorType.WHITE) {
    return isSubmit || disabled ? (
      <PrimaryWhiteSubmit disabled={disabled} {...rest} width={width}>
        {children}
      </PrimaryWhiteSubmit>
    ) : (
      <Link href={href} {...rest}>
        <PrimaryWhiteA {...rest} width={width}>
          {children}
        </PrimaryWhiteA>
      </Link>
    );
  }

  if (onColorType === hollowColorType.WhiteBorder) {
    return isSubmit || disabled ? (
      <PrimaryWhiteBorderSubmit disabled={disabled} {...rest} width={width}>
        {children}
      </PrimaryWhiteBorderSubmit>
    ) : (
      <Link href={href} {...rest}>
        <PrimaryWhiteBorderA {...rest} width={width}>
          {children}
        </PrimaryWhiteBorderA>
      </Link>
    );
  }

  if (onColorType === hollowColorType.whiteBorderError) {
    return isSubmit || disabled ? (
      <ErrorWhiteBorderSubmit disabled={disabled} {...rest} width={width}>
        {children}
      </ErrorWhiteBorderSubmit>
    ) : (
      <Link href={href} {...rest}>
        <ErrorWhiteBorderA {...rest} width={width}>
          {children}
        </ErrorWhiteBorderA>
      </Link>
    );
  }

  if (onColorType === hollowColorType.primaryBorder) {
    return isSubmit || disabled ? (
      <PrimaryBorderSubmit disabled={disabled} {...rest} width={width}>
        {children}
      </PrimaryBorderSubmit>
    ) : (
      <Link href={href} {...rest}>
        <PrimaryBorderA {...rest} width={width}>
          {children}
        </PrimaryBorderA>
      </Link>
    );
  }
  if (onColorType === hollowColorType.tertiaryBorder) {
    return isSubmit || disabled ? (
      <TertiaryBorderSubmit disabled={disabled} {...rest} width={width}>
        {children}
      </TertiaryBorderSubmit>
    ) : (
      <Link href={href} {...rest}>
        <TertiaryBorderA {...rest} width={width}>
          {children}
        </TertiaryBorderA>
      </Link>
    );
  }

  if (onColorType === hollowColorType.soyYo) {
    return isSubmit || disabled ? (
      <PrimarySoyYo disabled={disabled} {...rest} width={width}>
        {children}
      </PrimarySoyYo>
    ) : (
      <Link href={href} {...rest}>
        <PrimaryASoyYo {...rest} width={width}>
          {children}
        </PrimaryASoyYo>
      </Link>
    );
  }

  if (onColorType === hollowColorType.error) {
    return isSubmit || disabled ? (
      <Error disabled={disabled} {...rest} width={width}>
        {children}
      </Error>
    ) : (
      <Link href={href} {...rest}>
        <Error {...rest} width={width}>
          {children}
        </Error>
      </Link>
    );
  }

  if (onColorType === hollowColorType.fullWhite) {
    return isSubmit || disabled ? (
      <White disabled={disabled} {...rest} width={width}>
        {children}
      </White>
    ) : (
      <Link href={href} {...rest}>
        <White {...rest} width={width}>
          {children}
        </White>
      </Link>
    );
  }

  if (onColorType === hollowColorType.transparent) {
    return isSubmit || disabled ? (
      <TransparentSubmit disabled={disabled} {...rest} width={width}>
        {children}
      </TransparentSubmit>
    ) : (
      <Link href={href} {...rest}>
        <TransparentA {...rest} width={width}>
          {children}
        </TransparentA>
      </Link>
    );
  }

  return isSubmit || disabled ? (
    <WhiteSubmit disabled={disabled} {...rest} width={width}>
      {children}
    </WhiteSubmit>
  ) : (
    <Link href={href} {...rest}>
      <WhiteA {...rest} width={width}>
        {children}
      </WhiteA>
    </Link>
  );
};

const button = css`
  border: none;
  background-color: transparent;
  min-width: ${(props) => props.minWidth || '0px'};

  &:disabled {
    border: 1px solid ${colors.input.inactive};
    background-color: ${colors.input.inactive};
    color: ${colors.GRAY_1};
    cursor: default;
  }

  ${basicButton}
  ${anatomy}
`;

// BLANCO
const white = css`
  ${button}
  color: ${(props) => (props.color ? props.color : colors.GRAY_1)};
  border: ${(props) => (props.color ? `1px solid ${props.color}` : `1px solid ${colors.GRAY_1}`)};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: ${props.color !== colors.WHITE ? props.color : colors.primary};
      border: 1px solid ${props.color !== colors.WHITE ? props.color : colors.primary};
      color: ${colors.WHITE};
    `}

  &:hover:not([disabled]) {
    background-color: ${(props) => (props.color !== colors.WHITE ? props.color : colors.primary)};
    border: 1px solid ${(props) => (props.color !== colors.WHITE ? props.color : colors.primary)};
    color: ${colors.WHITE};
  }
`;

const WhiteA = styled.a`
  ${white}
`;

const WhiteSubmit = styled.button`
  ${white}
`;

// AZUL
const blue = css`
  ${button}
  color: ${colors.WHITE};
  border: 1px solid ${colors.BLUE};
  background-color: ${colors.BLUE};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 1px solid ${colors.BLUE};
      border: ${colors.BLUE};
      color: ${colors.primary};
    `}

  &:hover:not([disabled]) {
    background-color: ${colors.BLUE};
    border: 1px solid ${colors.WHITE};
    color: ${colors.primary};
  }
`;

const BlueA = styled.a`
  ${blue}
`;

const BlueSubmit = styled.button`
  ${blue}
`;

// AMARILLO
const yellow = css`
  ${button}
  color: ${colors.GRAY_1};
  border: 1px solid ${colors.secondary};
  background-color: ${colors.secondary};

  ${({ selected }) => selected
    && css`
      background-color: ${colors.secondary};
      border: 1px solid ${colors.secondary};
      color: ${colors.GRAY_1};
    `}

  &:hover:not([disabled]) {
    background-color: ${colors.WHITE};
    border: 1px solid ${colors.secondary};
    color: ${colors.GRAY_1};
  }
`;

const YellowA = styled.a`
  ${yellow}
`;

const YellowSubmit = styled.button`
  ${yellow}
`;

// GRAY
const gray = css`
  ${button}
  color: ${colors.GRAY_1};
  border: 1px solid ${colors.GRAY_1};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: ${colors.secondary};
      border: 1px solid ${colors.secondary};
      color: ${colors.GRAY_1};
    `}

  &:hover:not([disabled]) {
    background-color: ${(props) => (props.color ? props.color : colors.secondary)};
    border: 1px solid
      ${(props) => (props.color ? props.color : colors.secondary)};
    color: ${(props) => (props.color ? colors.WHITE : colors.GRAY_1)};
  }
`;

export const GrayA = styled.a`
  ${gray}
`;

const GraySubmit = styled.button`
  ${gray}
`;

// Primary blue
const primary = css`
  ${button}
  color: ${colors.WHITE};
  border: 1px solid ${colors.primary};
  background-color: ${colors.primary};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 1px solid ${colors.primary};
      border: ${colors.primary};
    `}

  &:hover:not([disabled]) {
    background-color: ${colors.BLUE};
    border: 1px solid ${colors.WHITE};
  }
`;

export const PrimaryA = styled.a`
  ${primary}
`;

const PrimarySubmit = styled.button`
  ${primary}
`;

// Primary blue, white border
const primaryBorder = css`
  ${button}
  color: ${colors.WHITE};
  border: 1px solid ${colors.WHITE};
  background-color: ${colors.primary};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 1px solid ${colors.primary};
      border: ${colors.primary};
    `}

  &:hover:not([disabled]) {
    background-color: ${colors.BLUE};
    border: 1px solid ${colors.WHITE};
  }
`;

const PrimaryBorderA = styled.a`
  ${primaryBorder}
`;

const PrimaryBorderSubmit = styled.button`
  ${primaryBorder}
`;

const tertiaryBorder = css`
  ${button}
  color: ${colors.WHITE};
  border: 1px solid ${colors.WHITE};
  background-color: ${colors.error};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 1px solid ${colors.error};
      border: ${colors.error};
    `}

  &:hover:not([disabled]) {
    background-color: ${colors.light.tertiary};
    border: 1px solid ${colors.WHITE};
  }
`;

const TertiaryBorderA = styled.a`
  ${tertiaryBorder}
`;

const TertiaryBorderSubmit = styled.button`
  ${tertiaryBorder}
`;

// White
const whiteStyle = css`
  ${button}
  color: ${colors.primary};
  border: 1px solid ${colors.WHITE};
  background-color: ${colors.WHITE};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 1px solid ${colors.primary};
      border: ${colors.primary};
    `}

  &:hover:not([disabled]) {
    color: ${colors.WHITE};
    background-color: ${colors.BLUE};
    border: 1px solid ${colors.WHITE};
  }
`;

export const PrimaryWhiteA = styled.a`
  ${whiteStyle}
`;

const PrimaryWhiteSubmit = styled.button`
  ${whiteStyle}
`;

// White border
const whiteBorderStyle = css`
  ${button}
  color: ${colors.primary};
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.primary};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 1px solid ${colors.primary};
      border: ${colors.primary};
    `}

  &:hover:not([disabled]) {
    color: ${colors.WHITE};
    background-color: ${colors.BLUE};
    border: 1px solid ${colors.WHITE};
  }
`;

const PrimaryWhiteBorderA = styled.a`
  ${whiteBorderStyle}
`;

const PrimaryWhiteBorderSubmit = styled.button`
  ${whiteBorderStyle}
`;

const whiteBorderError = css`
  ${button}
  color: ${colors.error};
  background-color: ${colors.WHITE};
 

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 1px solid ${colors.error};
      border: ${colors.error};
    `}

  &:hover:not([disabled]) {
    color: ${colors.WHITE};
    background-color: ${colors.light.tertiary};
    border: 1px solid ${colors.WHITE};
  }
`;
export const ErrorWhiteBorderA = styled.a`
  ${whiteBorderError}
`;

const ErrorWhiteBorderSubmit = styled.button`
  ${whiteBorderError}
`;

// SoyYo Purple
const soyYo = css`
  ${button}
  color: ${colors.WHITE};
  border: 1px solid ${colors.soyYo};
  background-color: ${colors.soyYo};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 1px solid ${colors.soyYo};
      border: ${colors.soyYo};
      color: ${colors.soyYo};
    `}

  &:hover:not([disabled]) {
    background-color: ${colors.soyYo};
    border: 1px solid ${colors.WHITE};
    color: ${colors.BLUE};
  }
`;

const PrimarySoyYo = styled.button`
  ${soyYo}
`;

const PrimaryASoyYo = styled.a`
  ${soyYo}
`;

const error = css`
  ${button}
  color: ${colors.WHITE};
  border: 1px solid ${colors.error};
  background-color: ${colors.error};

  &:hover:not([disabled]) {
    background-color: ${colors.light.tertiary};
    border: 1px solid ${colors.light.tertiary};
  }
`;

const Error = styled.button`
  ${error}
`;

const whiteForPrimary = css`
  ${button}
  color: ${(props) => (props.color ? props.color : colors.primary)};
  border: ${(props) => (props.color ? `1px solid ${props.color}` : `1px solid ${colors.WHITE}`)};
  background-color: ${colors.WHITE};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: ${colors.WHITE};
      border: 1px solid ${colors.WHITE};
      color: ${colors.primary};
    `}

  &:hover:not([disabled]) {
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
    color: ${colors.WHITE};
  }
`;

const White = styled.button`
  ${whiteForPrimary}
`;

const transparent = css`
  ${button}
  color: ${colors.primary};
  border: 2px solid ${colors.primary};

  ${(props) => props.actived
    && !props.disabled
    && css`
      background-color: 2px solid ${colors.WHITE};
      border: ${colors.WHITE};
      color: ${colors.primary};
    `}

  &:hover:not([disabled]) {
    background-color: ${colors.WHITE};
    border: 2px solid ${colors.WHITE};
    color: ${colors.primary};
  }
`;

const TransparentSubmit = styled.button`
  ${transparent}
`;

const TransparentA = styled.a`
  ${transparent}
`;
