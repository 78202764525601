const SUCCESS = 'SUCCESS_PORTAL_TOKEN';

const initialState = {
  token: undefined,
  expirationDate: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        expirationDate: action.payload.expirationDate,
      };
    default:
      return state;
  }
};

export default reducer;

export const setPortalToken = ({ token, expirationDate }) => ({
  type: SUCCESS,
  payload: { token, expirationDate },
});
