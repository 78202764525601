import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'wouter';
import { usePortalAuthenticate } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { setPortalToken } from 'store/portalToken';
import { useCancelToken } from '../useCancelToken';

export const usePortalToken = () => {
  const [location] = useLocation();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const { token, expirationDate } = useSelector((store) => store.portalToken);
  const dispatch = useDispatch();
  const {
    isMounted,
    logCallback,
  } = useCancelToken();

  const refreshToken = useCallback(() => {
    setValue({});
    usePortalAuthenticate()
      .then((data) => {
        if (isMounted) {
          setLoading(false);
          setValue(data);
        }
      })
      .catch((err) => {
        logCallback(err, () => {
          setLoading(false);
        });
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!expirationDate || new Date(new Date().toUTCString().split('GMT')[0]) >= new Date(expirationDate)) {
      refreshToken();
    } else {
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (value) {
      dispatch(setPortalToken(value));
    }
  }, [value]);
  return {
    token,
    refreshToken,
    isLoadingToken: loading,
  };
};
