/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { getSingleProjectService } from 'services';
import { useCancelToken } from '../useCancelToken';

export const useSingleProject = ({ tag = null, id = null }) => {
  const [state, setState] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isMounted, source, logCallback } = useCancelToken();
  useEffect(() => {
    if (tag || id) {
      setIsLoading(true);
      setState(null);
      getSingleProjectService({ tag, id, source })
        .then((data) => {
          if (isMounted) {
            setIsLoading(false);
            if (
              data.status === 'OPEN'
              && data.maxAmount === data.currentAmount
            ) {
              /* eslint-disable-next-line no-param-reassign */
              data.status = 'COLLECTING';
            }
            const index = data?.ods?.findIndex((item) => item === 'Otro');
            if (index !== -1) {
              data?.ods.splice(index, 1);
            }
            setState(data);
          }
        })
        .catch((err) => logCallback(err, () => {
          setIsLoading(false);
          setIsError(true);
          setState({});
        }));
    }
  }, [tag, id]);

  return {
    singleProject: state,
    isLoadingSingleProject: isLoading,
    isErrorSingleProject: isError,
  };
};
