import React, { useState } from 'react';
import { Div, colors, weight } from 'globalStyle';
import { useUser, useWallet } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { closePopUp, openPopUp } from 'store/popup';
import { useLocation as useLoc } from 'hooks/useLocation';
import { TextLight } from 'components/Texts';
import { Hollow, Ghost } from 'components/Buttons/';
import { Recharge } from 'Layout/components/Menu/components/AvailableMoney/Recharge';
import { PayInterbank } from 'Layout/components/Menu/components/AvailableMoney/PayInterbank';
import eye from 'assets/icons/eye.svg';
import noEye from 'assets/icons/no-eye.svg';
import reload from 'assets/icons/reload.svg';
import styled from 'styled-components';
import { formatNumber } from 'util/formatNumber';
import { useWalletCache } from 'hooks/useWalletCache';
import { RechargeStatus } from 'Layout/components/Menu/components/AvailableMoney/RechargeStatus';
import Skeleton from 'react-loading-skeleton';
import { routes } from 'routes';

export const CardBalance = ({ bgColor = colors.background.GRAY_3, pd }) => {
  const [modal, setModal] = useState(false);
  const { location } = useLoc();
  const { visible, setValuesVisibility } = useWalletCache();
  const { user } = useUser();
  const { wallet, fetch, isLoadingWallet } = useWallet({
    useCache: () => location
      && location.current.includes('/perfil')
      && location.previous.includes('/perfil'),
  });

  const isUserVip = user.info?.userType === 'VIP_INVESTOR' || user.info?.userType === 'VIP_COMPANY';
  const available = isUserVip
    ? wallet?.available
    : wallet?.bankBalance?.available;

  const handelDrop = () => {
    setValuesVisibility(!visible);
  };

  const dispatch = useDispatch();
  const isOpen = useSelector((store) => store.popup.isOpen);
  const handleClose = () => dispatch(closePopUp());

  const handleInterbank = () => {
    dispatch(openPopUp({ type: 'interbank' }));
  };

  const points = [1, 2, 3, 4];
  const HiddenWallet = () => (
    <Div isFlex style={{ columnGap: '2px' }} width="100%" justify="flex-end">
      <TextLight size={13} weight={weight.SEMI_BOLD} width="10px">
        $
      </TextLight>
      {points.map(() => (
        <Circle />
      ))}
    </Div>
  );
  if (isLoadingWallet) {
    return <Skeleton height="100%" style={{ marginBottom: '20px' }} />;
  }
  return (
    <Div
      isFlex
      direction="column"
      justify="space-between"
      align="start"
      height="100%"
      p={pd || '12px 15px'}
      width="100%"
      backgroundColor={bgColor}
      radius="15px"
    >
      <Div width="100%" isFlex justify="space-between">
        <Div
          onClick={() => {
            fetch();
          }}
        >
          <img
            src={reload}
            alt="recargar saldo"
            style={{ cursor: 'pointer' }}
          />
        </Div>
        <Div>
          <TextLight size={14} weight={weight.BOLD}>
            Saldo
          </TextLight>
        </Div>
        <Div>
          <Img src={visible ? eye : noEye} alt="icon" onClick={handelDrop} />
        </Div>
      </Div>

      <Div
        width="100%"
        height="100%"
        isFlex
        direction="column"
        justify="space-evenly"
      >
        <Div isFlex justify="space-between" width="100%">
          <TextLight size={13} weight={weight.SEMI_BOLD} align="start">
            Disponible:
          </TextLight>
          <TextLight size={13} align="end">
            {visible ? `${formatNumber(available || 0)}` : <HiddenWallet />}
          </TextLight>
        </Div>

        <Div isFlex justify="space-between" width="100%">
          <TextLight size={13} weight={weight.SEMI_BOLD} align="start">
            Por confirmar:
          </TextLight>
          <TextLight size={13} align="end">
            {visible ? (
              `${formatNumber(wallet?.toConfirm || 0)}`
            ) : (
              <HiddenWallet />
            )}
          </TextLight>
        </Div>

        <Div isFlex justify="space-between" width="100%">
          <TextLight size={13} weight={weight.SEMI_BOLD} align="start">
            Saldo total:
          </TextLight>
          <TextLight size={13} align="end">
            {visible ? (
              `${formatNumber((available || 0) + (wallet?.toConfirm || 0))}`
            ) : (
              <HiddenWallet />
            )}
          </TextLight>
        </Div>
      </Div>

      {!isUserVip && (
        <Div isFlex width="100%" justify="space-between">
          <Hollow
            isSubmit
            onClick={() => {
              handleInterbank();
            }}
            padding="7px 0"
            size="12px"
            onColorType="white"
            color={colors.primary}
            style={{ borderRadius: '20px', width: '48%' }}
          >
            Retirar
          </Hollow>

          <Hollow
            isSubmit
            onColorType="primary"
            onClick={() => setModal(!modal)}
            padding="7px 0"
            size="12px"
            style={{ borderRadius: '20px', width: '48%' }}
          >
            Recargar
          </Hollow>
        </Div>
      )}

      {isUserVip && (
        <TextLight
          size={12}
          style={{
            textWrap: 'balance',
            textAlign: 'center',
            display: 'flex',
            itemAlign: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          Para recargar tu saldo comunícate con uno de
          {' '}
          <Ghost
            size={12}
            href={routes.message.contactUs}
            underline
            italic
            style={{ display: 'block', textAlign: 'center' }}
            color={colors.primary}
            weight={weight.SEMI_BOLD}
          >
            nuestros asesores
          </Ghost>
        </TextLight>
      )}

      {isOpen && <PayInterbank isOpen={isOpen} onClick={handleClose} />}

      {modal && (
        <Recharge
          isOpen={modal}
          userData={user?.info}
          onClick={() => {
            setModal(false);
          }}
        />
      )}

      <RechargeStatus />
    </Div>
  );
};

const Img = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${colors.GRAY_1};
  border-radius: 5px;
`;
