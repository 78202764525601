import { Bold } from 'components/Texts';
import { useUser } from 'hooks';
import React, { useEffect, useState } from 'react';
import {
  mapDropdowns, mapTowns, findCiiu, getDropDownsForFront,
} from 'services';

import TermsAndConditions from './components/TermsAndConditions';

const occupations = {
  employee: 'Empleado',
  independent: 'Independiente',
  unemployeed: 'Desempleado',
  retired: 'Pensionado',
};
const occupationNext = (occupation) => {
  if (occupation === 'unemployeed' || occupation === 'retired') {
    return 'monthlyWinnings';
  }
  if (occupation === 'employee') {
    return 'companyName';
  }
  return 'economicActivity';
};

const getNullNumericValue = (name) => (v) => {
  if (v) {
    return undefined;
  }
  return { [name]: 0 };
};

export const FINANCIAL_SAVE = Object.freeze([
  'department',
  'municipality',
  'address',
  'additionalInformation',
  'occupation',
  'ciiuActivity',
  'companyName',
  'monthlyIncome',
  'expenses',
  'assets',
  'askPassives',
  'liabilities',
  'askAdditionalIncome',
  'othersIncomes',
  'othersIncomesConcept',
  'askAdditionalCollabInvestments',
  'otherInvestments',
  'ciiuDivision',
  'foreignOperations',
  'operationType',
  'entityName',
  'country',
  'city',
  'operationConcept',
  'coin',
  'amount',
  'termsAndConditions',
  'regulationBloom',
  'coopcentralRegulation',
]);

export const FINANCIAL_ORDER = Object.freeze([
  {
    field: 'department',
  },
  {
    field: 'municipality',
    step: 'city',
  },
  {
    field: 'address',
  },
  {
    field: 'additionalInformation',
    step: 'askAddressAdditionalInfo',
    optional: true,
  },
  {
    field: 'occupation',
  },
  {
    field: 'ciiuDivision',
    step: 'economicActivity',
  },
  {
    field: 'ciiuActivity',
    step: 'economicActivity',
  },
  {
    field: 'companyName',
    skip: (currentValues) => (currentValues.ciiuDivision === '002' || currentValues.ciiuDivision === '000'),
  },
  {
    field: 'monthlyIncome',
    step: 'monthlyWinnings',
  },
  {
    field: 'expenses',
    step: 'monthlyExpenses',
  },
  {
    field: 'assets',
    step: 'actives',
  },
  {
    field: 'liabilities',
    step: 'askPassives',
    optional: true,
  },
  {
    field: 'othersIncomes',
    step: 'askAdditionalIncome',
    optional: true,
    dependencies: [
      {
        field: 'othersIncomesConcept',
        step: 'additionalIncomeSource',
      },
    ],
  },
  {
    optional: true,
    field: 'otherInvestments',
    step: 'askAdditionalCollabInvestments',
  },
  {
    message: [
      'Estábamos hablando de las operaciones internacionales que realizas',
    ],
    field: 'foreignOperations',
    step: 'askForeignOperations',
    optional: true,
    dependencies: [
      {
        field: 'operationType',
        step: 'internationalType',
      },
      {
        field: 'entityName',
        step: 'internationalEntity',
      },
      {
        field: 'operationConcept',
        step: 'internationalConcept',
      },
      {
        field: 'country',
        step: 'internationalCountry',
      },
      /*
      {
        field: 'city',
        step: '',
      },
      */
      {
        field: 'coin',
        step: 'internationalCurrency',
      },
      {
        field: 'amount',
        step: 'internationalAmount',
      },
    ],
  },
  {
    field: 'termsAndConditions',
    step: 'conditions',
  },
  {
    field: 'regulationBloom',
    step: 'conditions',
  },
  {
    field: 'coopcentralRegulation',
    step: 'conditions',
  },
]);

export const financialChatScript = (values, onFinish) => {
  const { user } = useUser();
  const [ciiu, setCiuu] = useState({});
  const [cities, setCities] = useState();
  const [script, setScript] = useState({});
  const [ocupations, setOcupations] = useState();
  const [departments, setDepartments] = useState();
  const [prevValues, setPrevValues] = useState(values);
  const [generalValues, setGeneralValues] = useState({});

  useEffect(() => {
    mapDropdowns()
      .then((res) => {
        setDepartments(res.data);
      });
    setOcupations(occupations);
    findCiiu()
      .then((res) => {
        const divisions = {};
        const data = res.data.reduce((obj, v) => {
          Object.assign(obj, v.activities);
          Object.keys(v.activities).forEach((activity) => {
            divisions[activity] = v.divisions;
          });
          return obj;
        }, {});
        setCiuu({ data, divisions });
      });
    getDropDownsForFront()
      .then((res) => {
        setGeneralValues(res.data);
      });
  }, []);

  useEffect(() => {
    const isNew = (field) => prevValues[field] !== values[field];
    if (isNew('department') && values.department) {
      mapTowns({ department: values.department })
        .then((res) => {
          setCities(res.data);
        });
    }
    setPrevValues(values);
  }, [values]);

  useEffect(() => {
    if (user) {
      const { info } = user;
      setScript({
        country: {
          message: [
            (
              <p>
                Primero que todo, cuéntame ¿Tu país de residencia es
                <Bold>
                  {' Colombia '}
                </Bold>
                ?
              </p>
            ),
          ],
          type: 'bool',
          field: 'country',
          getValue: (v) => v && 'COLOMBIA',
          next: (v) => (v ? 'department' : 'countryList'),
        },
        department: {
          message: [
            (
              <p>
                <Bold>
                  {`Hola ${info.firstName || info.name}`}
                </Bold>
                , Soy Bloom tu asistente virtual y te voy a ayudar en este proceso.
              </p>
            ),
            'Primero que todo, cuéntame, ¿en qué departamento te encuentras ubicado?',
          ],
          type: 'list',
          next: 'city',
          values: departments,
          field: 'department',
          name: 'Departamentos',
          question: '¿En qué departamento te encuentras ubicado?',
        },
        city: {
          message: [
            '¡Que interesante!\n¿Podrias especificarnos en que municipio?',
          ],
          question: '¿En qué departamento te encuentras ubicado?',
          type: 'list',
          field: 'municipality',
          values: cities,
          next: 'address',
          name: 'Municipio',
        },
        address: {
          message: [
            'Ingresa tu dirección de residencia',
          ],
          type: 'text',
          field: 'address',
          next: 'askAddressAdditionalInfo',
        },
        askAddressAdditionalInfo: {
          message: [
            '¿Deseas agregar alguna información adicional de tu residencia?',
          ],
          type: 'bool',
          field: 'askAddressAdditionalInfo',
          next: (v) => (v ? 'addressAdditionalInfo' : 'occupation'),
        },
        addressAdditionalInfo: {
          message: [
            'Escribe a continuación los detalles de tu residencia',
          ],
          type: 'text',
          field: 'additionalInformation',
          next: 'occupation',
        },
        occupation: {
          message: [
            'Ahora... hablemos un poco de tu vida laboral\n¿Cúal es tu ocupación actual? ',
          ],
          question: '¿Cúal es tu ocupación actual?',
          type: 'list',
          field: 'occupation',
          name: 'Ocupación',
          values: ocupations,
          next: (v) => occupationNext(v),
          getValue: (value) => {
            if (value === 'unemployeed' || value === 'retired') {
              return {
                occupation: value,
                ciiuDivision: value === 'retired' ? '002' : '000',
                ciiuActivity: value === 'retired' ? '0020' : '0000',
              };
            }
            if (value === 'employee') {
              return {
                occupation: value,
                ciiuDivision: '001',
                ciiuActivity: '0010',
              };
            }
            return value;
          },
        },
        economicActivity: {
          message: [
            '¿Cual es la actividad económica a la que te dedicas?',
          ],
          type: 'list',
          field: 'ciiuActivity',
          name: 'Actividad económica',
          values: ciiu.data,
          next: 'companyName',
          getValue: (v) => ({
            ciiuActivity: v,
            ciiuDivision: ciiu.divisions[v],
          }),
        },
        companyName: {
          message: [
            '¿Cúal es el nombre de la empresa donde trabajas?',
          ],
          type: 'text',
          field: 'companyName',
          next: 'monthlyWinnings',
        },
        monthlyWinnings: {
          message: [
            'Recuerda que al ser una entidad vigilada por la Superintendencia Financiera de Colombia, todos tus datos serán resguardados bajo los más altos estándares de seguridad, tú eres dueño de esta información',
            'Cuéntanos un poco sobre tus finanzas  ¿Podrías decirnos cuánto ganas al mes?',
            'Ingresa esta información lo más concreta posible.',
          ],
          question: '¿Cuánto ganas al mes?',
          type: 'currency',
          field: 'monthlyIncome',
          next: 'monthlyExpenses',
        },
        monthlyExpenses: {
          message: [
            '¿Cuánto gastas al mes aproximadamente?',
          ],
          type: 'currency',
          field: 'expenses',
          next: 'actives',
        },
        actives: {
          message: [
            '¿Cuál es el valor de las cosas que tienes a tu nombre?',
          ],
          type: 'currency',
          field: 'assets',
          next: 'askPassives',
        },
        askPassives: {
          message: [
            'Aparte de esta información ¿Tienes algun tipo de deuda en este momento? ',
          ],
          question: '¿Tienes algun tipo de deuda en este momento?',
          type: 'bool',
          field: 'askPassives',
          getValue: getNullNumericValue('liabilities'),
          next: (v) => (v ? 'passives' : 'askAdditionalIncome'),
        },
        passives: {
          message: [
            '¿De qué valor es esta deuda?',
          ],
          type: 'currency',
          field: 'liabilities',
          next: 'askAdditionalIncome',
        },
        askAdditionalIncome: {
          message: [
            '¿Cuentas con algún otro ingreso mensual?',
          ],
          type: 'bool',
          field: 'askAdditionalIncome',
          getValue: getNullNumericValue('othersIncomes'),
          next: (v) => (v ? 'additionalIncome' : 'askAdditionalCollabInvestments'),
        },
        additionalIncome: {
          message: [
            'Especifica el monto de este otro ingreso',
          ],
          type: 'currency',
          field: 'othersIncomes',
          next: 'additionalIncomeSource',
        },
        additionalIncomeSource: {
          message: [
            'Cuéntanos un poco sobre tus ingresos adicionales y ¿Cómo los obtienes?',
          ],
          type: 'text',
          field: 'othersIncomesConcept',
          next: 'askAdditionalCollabInvestments',
        },
        askAdditionalCollabInvestments: {
          message: [
            'En este momento, ¿Cuentas con otras inversiones de financiación colaborativa?',
          ],
          type: 'bool',
          field: 'askAdditionalCollabInvestments',
          getValue: getNullNumericValue('otherInvestments'),
          next: (v) => (v ? 'aditionalCollabInvestments' : 'askForeignOperations'),
        },
        aditionalCollabInvestments: {
          message: [
            '¿Cuánto es el monto de esta inversión?',
          ],
          type: 'currency',
          field: 'otherInvestments',
          next: 'askForeignOperations',
        },
        askForeignOperations: {
          message: [
            '!Super! Una última pregunta, ¿Realizas operaciones en el exterior?',
          ],
          question: '¿Realizas operaciones en el exterior?',
          type: 'bool',
          field: 'foreignOperations',
          getValue: (v) => {
            if (v) {
              return true;
            }
            return {
              amount: 0,
              foreignOperations: false,
            };
          },
          next: (v) => (v ? 'internationalType' : 'conditions'),
        },
        internationalType: {
          message: [
            'Cuentanos un poco más sobre esto, ¿Que tipo de operación realizas?',
          ],
          question: '¿Que tipo de operación realizas?',
          type: 'list',
          name: 'Operaciones',
          field: 'operationType',
          values: generalValues.bccInternationalOperationType,
          next: 'internationalEntity',
        },
        internationalEntity: {
          message: [
            'Y ¿En qué entidad realizas estas operaciones?',
          ],
          type: 'text',
          field: 'entityName',
          next: 'internationalConcept',
        },
        internationalConcept: {
          message: [
            '¿Por qué concepto en específico?',
          ],
          question: '¿Por qué concepto en específico realizas estas operaciones internacionales?',
          type: 'text',
          field: 'operationConcept',
          next: 'internationalCountry',
        },
        internationalCountry: {
          message: [
            'Que interesante, cuéntanos ¿En dónde realizas estas operaciones?',
          ],
          question: '¿En dónde realizas estas operaciones?',
          type: 'list',
          name: 'País',
          field: 'country',
          values: generalValues.countries,
          next: 'internationalCurrency',
        },
        internationalCurrency: {
          message: [
            '¿En qué moneda realizas estas operaciones?',
          ],
          type: 'list',
          name: 'Tipo de moneda',
          field: 'coin',
          values: generalValues.bccInternationalCurrencyTypes,
          next: 'internationalAmount',
        },
        internationalAmount: {
          message: [
            'Por último, ¿Cuál es el monto de tus operaciones?',
          ],
          type: 'currency',
          field: 'amount',
          next: 'conditions',
        },
        conditions: {
          message: [
            'Para finalizar con nuestra conversación te pedimos que leas y aceptes nuestros documentos.',
          ],
          type: 'custom',
          field: 'conditions',
          customComponent: TermsAndConditions,
          next: (v) => (v ? onFinish : 'conditions'),
        },
        loading: {
          message: [
            '...',
          ],
          noMinWidth: true,
        },
        test: {
          message: [
            '',
            '',
          ],
        },
      });
    } else {
      setScript({});
    }
  }, [user, departments, cities, ocupations, generalValues]);

  return script;
};
