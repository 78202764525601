import React, { useEffect, useState, useRef } from 'react';
import { Bold } from 'components/Texts';
import { Grid } from '@material-ui/core';

import FinancialChatMessage from './FinancialChatMessage';
import FinancialChatInput from './FinancialChatInput';
import BooleanChat from './components/BooleanChat';

const TRUE_TEXT = 'Sí, confirmar';
const FALSE_TEXT = 'No, editar';

export const getFinancialValue = (type, value, activeValue) => {
  if (typeof value === 'object' && value.textValue) {
    return value.textValue;
  }
  if (type === 'bool') {
    if (value) {
      return 'Sí';
    }
    return 'No';
  }
  if (type === 'list') {
    const { values } = activeValue;
    if (Array.isArray(values)) {
      return value;
    }
    return activeValue.values[value];
  }
  return value;
};

export default function FinancialChatDelayer({ data, update, addMessage }) {
  const lastRef = useRef();
  const [show, setShow] = useState(0);
  const [tempValue, setTempValue] = useState();
  const [tempText, setTempText] = useState();
  const [lastData, setLastData] = useState(data);
  const [counter, setCounter] = useState(0);
  const next = () => {
    const time = setTimeout(() => {
      setShow((currShow) => {
        const newValue = currShow + 1;
        if ((tempText && newValue < tempText?.length + 1) || newValue < data?.message?.length) {
          next();
        }
        return newValue;
      });
    }, Math.random() * 5000);
    return () => clearTimeout(time);
  };
  const setTemporalText = (text) => {
    setShow(0);
    next();
    setTempText(text);
  };
  const setTemporalValue = (newValue) => {
    if (!tempText) {
      addMessage({
        received: true,
        key: `${data?.field}-received`,
        messages: data.message,
      });
      setCounter((v) => v + 1);
    } else {
      addMessage({
        received: true,
        messages: tempText,
        key: `new-message-${counter + 1}`,
      });
      setCounter((v) => v + 2);
    }
    addMessage({
      key: `new-message-${counter}`,
      messages: [getFinancialValue(data.type, newValue, data)],
    });
    setTemporalText([(
      <>
        <Bold>Confirma tu información</Bold>
        <br />
        {`"${getFinancialValue(data.type, newValue, data)}"`}
        <br />
        ¿Es correcta?
      </>
    )]);
    setTempValue(newValue);
  };
  const onConfirm = (confirm) => {
    addMessage({
      received: true,
      messages: tempText,
      key: `new-message-${counter}`,
    });
    let userText = FALSE_TEXT;
    if (confirm) {
      update(tempValue);
      setTempValue();
      setTemporalText();
      userText = TRUE_TEXT;
    } else {
      setTempValue();
      if (data.question) {
        setTemporalText([
          'Ingresa tu información correcta a continuación',
          data.question,
        ]);
      } else {
        setTemporalText([
          'Ingresa tu información correcta a continuación',
          ...data.message,
        ]);
      }
    }
    addMessage({
      messages: userText,
      key: `new-message-${counter + 1}`,
    });
    setCounter((v) => v + 2);
  };
  useEffect(() => {
    lastRef?.current?.scrollIntoView();
  }, [show]);
  useEffect(() => {
    if (data) {
      if (!lastData || (data.field !== lastData.field)) {
        setShow(0);
        next();
        setLastData(data);
      }
      if (data.value !== undefined && data.value !== tempValue) {
        setTemporalValue(data.value);
      }
    }
  }, [data]);
  return (
    <Grid container>
      {tempText
        ? (
          <FinancialChatMessage
            noMinWidth={show < tempText?.length}
            messages={
              show < tempText?.length
                ? [...tempText?.slice(0, show), <Bold>...</Bold>]
                : tempText
            }
            received
          />
        )
        : (
          <FinancialChatMessage
            noMinWidth={(data && data.noMinWidth) || (show < data?.message?.length)}
            messages={
              show < data?.message?.length
                ? [...data?.message?.slice(0, show), <Bold>...</Bold>]
                : data?.message
            }
            received
          />
        )}
      {tempValue === undefined
        && ((tempText && tempText?.length <= show)
        || (!tempText && data?.message?.length <= show))
        && (
        <FinancialChatInput update={setTemporalValue} {...data} />
        )}
      {tempValue !== undefined && tempText && tempText.length <= show && (
        <BooleanChat
          update={onConfirm}
          trueOptions={{ text: TRUE_TEXT }}
          falseOptions={{ text: FALSE_TEXT }}
        />
      )}
      <div style={{ width: '100%' }} ref={lastRef} />
    </Grid>
  );
}
