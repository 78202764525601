/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useEffect, useCallback } from 'react';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Box from '@material-ui/core/Box';
import { Link, useLocation } from 'wouter';
import { useUser, useModal } from 'hooks';
import {
  colors, Div, Container, weight, Figure,
} from 'globalStyle';
import { Ghost } from 'components/Buttons';
import { routes } from 'routes';
import logo from 'assets/logo.svg';
import TabPanel from 'components/TabPanel';

import { useDispatch, useSelector } from 'react-redux';
import { closePopUp, openPopUp } from 'store/popup';

import { useGeo } from 'context/GeoInfoContext';
// import NotAvailable from 'components/Modals/NotAvailable';
import { Login } from './components/Login';
import { Recover } from './components/Recover';
import { Sidebar } from './components/Sidebar';
import { NavbarDesktop } from './NavbarDesktop';
import { Register } from './components/Register';
import { Content, HamburguerMenu } from './styles';
import { VerticalMenu } from './components/VerticalMenu';

export const CLIENT_TYPE = Object.freeze({
  INVESTOR: 0,
  COMPANY: 1,
});

const PopUps = ({ location, login }) => {
  const isDesktop = useMediaQuery(1023);
  const open = useSelector((store) => store.popup.open);
  const isOpen = useSelector((store) => store.popup.isOpen);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closePopUp());
  const [clientType, setClientType] = React.useState(CLIENT_TYPE.INVESTOR);

  const recoverIndex = ['recover-password', 'recover-device'].find((item) => item === open);

  useEffect(() => {
    if (isOpen) { dispatch(closePopUp()); }
  }, [location]);

  return (
    <div>
      <TabPanel value={open} index="login">
        <Login
          isOpen
          login={login}
          onClick={handleClose}
          value={clientType}
          CLIENT_TYPE={CLIENT_TYPE}
          setValue={setClientType}
        />
      </TabPanel>
      <TabPanel value={open} index="register">
        {
          <Register
            isOpen
            login={login}
            onClick={handleClose}
          />
        }
        {/* <NotAvailable open onClose={handleClose} /> */}
      </TabPanel>
      <TabPanel value={open} index={recoverIndex}>
        <Recover
          isOpen
          isDesktop={isDesktop}
          recoverIndex={recoverIndex}
          onClick={handleClose}
          clientType={clientType}
        />
      </TabPanel>
    </div>
  );
};

export const Navbar = () => {
  const [location, setLocation] = useLocation();
  const {
    isLogin,
    logout,
    login,
    type,
    user,
  } = useUser();
  const isDesktopOrLaptop = useMediaQuery(1023);

  const props = {
    isLogin,
    logout,
    login,
    type,
    user,
    setLocation,
  };

  const Component = isDesktopOrLaptop ? NavbarDesktop : NavbarMobile;

  return (
    <>
      <Component {...props} />
      <PopUps location={location} login={login} />
    </>
  );
};

function NavbarMobile() {
  const [open, openSidebar, closeSidebar] = useModal(false);
  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        style={{
          zIndex: 100,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '57.5px',
          backgroundColor: colors.WHITE,
          boxShadow: '0px 1px 5px -1px rgba(0,0,0,0.34)',
        }}
      >
        <Link href="/">
          <Figure ml={38} height="20px" width="69px" style={{ cursor: 'pointer' }}>
            <img src={logo} alt="logo" />
          </Figure>
        </Link>
        <HamburguerMenu onClick={openSidebar} isOpen={open} />
      </Box>
      <Sidebar open={open} closeSidebar={closeSidebar} />
    </div>
  );
}
