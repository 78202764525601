import { Fetch } from 'lib/Fetch';

export function userInvestmentsService() {
  return new Fetch()
    .url('/user/characteristic-client/info-investments')
    .method('get')
    .userToken()
    .send();
}

export function userBondCertificate({ projectId }) {
  return new Fetch()
    .url(`/user/characteristic-client/bond-certificate/${projectId}`)
    .method('get')
    .userToken()
    .send();
}

export function userPaymentPlan({ projectId }) {
  return new Fetch('api_2')
    .url(`/user/characteristic-client/excel-user-payment-plan/${projectId}`)
    .method('get')
    .userToken()
    .send();
}
