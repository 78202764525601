const UPDATE_INVESTMENTS = 'UPDATE_INVESTMENTS';
const REMOVE_INVESTMENTS = 'REMOVE_INVESTMENTS';

const initialState = {
  investments: {},
};

const investmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INVESTMENTS:
      return {
        ...state,
        investments: action.payload,
      };
    case REMOVE_INVESTMENTS:
      return {
        ...state,
        investments: action.payload,
      };

    default:
      return state;
  }
};

export default investmentsReducer;

export const updateInvestments = (investments) => ({
  type: UPDATE_INVESTMENTS,
  payload: investments,
});

export const removeInvestments = () => ({
  type: REMOVE_INVESTMENTS,
});
