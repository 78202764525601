import * as React from 'react';
import axios from 'axios';

const GeoInfoContext = React.createContext();

function GeoInfoProvider({ children }) {
  const [state, setState] = React.useState({ country: '' });

  const getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then(({ data }) => {
        setState({ ...state, country: data?.country_name || '' });
      });
  };

  React.useEffect(() => {
    getGeoInfo();
  }, []);

  return <GeoInfoContext.Provider value={state}>{children}</GeoInfoContext.Provider>;
}

function useGeo() {
  const context = React.useContext(GeoInfoContext);
  if (context === undefined) {
    throw new Error('useGeo must be used within a GeoInfoProvide');
  }
  return context;
}

export { GeoInfoProvider, useGeo };
