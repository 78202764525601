import { Fetch } from 'lib/Fetch';
import { historyStateType } from 'types';

const findStateType = (status) => {
  const value = historyStateType.find((obj) => obj.type === status)?.state;
  if (value) return value;
  return 'Otra';
};

function fromApiResponseToHistory({ data }) {
  return data.map((obj) => ({
    ...obj,
    // isAddition: data.type === 'ADDITION',
    status: findStateType(obj.status),
  }));
}
export function transactionHistoryCompany({
  initialDate,
  finalDate,
}) {
  return new Fetch()
    .url('/company/financial/company-history')
    .method('post')
    .userToken()
    .data({
      initialDate,
      finalDate,
    })
    .send()
    .then(fromApiResponseToHistory);
}
