/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import moment from 'moment';
import { useLocation } from 'hooks/useLocation';
import { useMediaQuery, useUser } from 'hooks';
import { TextLight } from 'components/Texts';
import { Hollow } from 'components/Buttons';
import { formatNumber } from 'util/formatNumber';
import {
  colors, Figure, Div, weight,
} from 'globalStyle';
import { transactionVoucher, transactionVoucherCompany } from 'services';
import { ErrorText } from 'components/Form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import closeIcon from 'assets/icons/closeIcon.svg';
import arrowLeft from 'assets/icons/arrowLeftHeader.svg';
import { LOGO_COOPCENTRAL } from 'types/fileType';
import { userType } from 'types';
import { routes } from 'routes';

const TYPE_PDF = 'application/pdf';

export const PaySuccess = ({
  setModal,
  isOpen,
  handleClose,
  goToBack,
  ...props
}) => {
  const isDesktop = useMediaQuery();
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { type } = useUser();
  const { data } = props;

  const date = moment(data?.transactionDateTime).format(
    'DD / MMMM / YYYY [a las] h:mm a',
  );
  const { location } = useLocation();
  const locationInclude = location?.current?.includes('/perfil');

  const handleTransactionVoucher = () => {
    setLoading(true);
    if (type === userType.USER) {
      transactionVoucher(data?.voucherNumber)
        .then(({ data: base64 }) => window
          .fetch(`data:${TYPE_PDF};base64,${base64}`)
          .then((res) => res.blob()))
        .then((blob) => {
          setLoading(false);

          const file = new window.File([blob], 'resumen.pdf', { TYPE_PDF });
          const fileURL = URL.createObjectURL(file);

          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', 'resumen.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(({ message }) => {
          setLoading(false);
          setErrorMessage(message);
        });
    }
    if (type === userType.COMPANY) {
      transactionVoucherCompany(data.voucherNumber)
        .then(({ data: base64 }) => window
          .fetch(`data:${TYPE_PDF};base64,${base64}`)
          .then((res) => res.blob()))
        .then((blob) => {
          setLoading(false);
          const file = new window.File([blob], 'resumen.pdf', { TYPE_PDF });
          const fileURL = URL.createObjectURL(file);

          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', 'resumen.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(({ message }) => {
          setLoading(false);
          setErrorMessage(message);
        });
    }
  };
  const info = {
    'Fecha:': date,
    'Estado:': 'En tránsito',
    'Nombre:': data?.destinationName,
    'Cuenta de origen:': ` ***********
    ${data?.originAccount?.substring(
    data?.originAccount.length - 4,
  )} del Banco Coopcentral`,
    'Entidad destino:': data?.nameBank,
    'Tipo de cuenta:': data?.accountType,
    'Número de cuenta:': ` ***********
    ${data?.destinationAccount?.substring(
    data?.destinationAccount.length - 4,
  )}`,
    'Costo de la operación:': `${formatNumber(data?.cost)}`,
    'Monto:': `${formatNumber(data?.amount)}`,
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: { borderRadius: '20px', padding: '30px' },
      }}
      maxWidth="md"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogContent
        style={{
          width: `${isDesktop ? 'calc(960px - 60px)' : 'auto'}`,
          padding: '0',
          overflowY: 'initial',
        }}
        align="center"
      >
        <Div isFlex justify="space-between" margin="0 0 20px">
          <Figure
            style={{
              width: '25px',
              height: '25px',
              cursor: 'pointer',
            }}
            onClick={() => {
              goToBack();
            }}
          >
            <img src={arrowLeft} alt="arrowLeft" />
          </Figure>
          <Div>
            <TextLight size={20} color={colors.primary} weight={weight.BOLD}>
              Transferencia en proceso
            </TextLight>
          </Div>
          <Figure
            style={{
              width: '18px',
              height: '18px',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <img src={closeIcon} alt="cerrar" />
          </Figure>
        </Div>
        <Div p={`${isDesktop ? '0 90px' : '0 10px'}`}>
          <TextLight size={13} mb={3} weight={weight.BOLD}>
            No de comprobante
          </TextLight>
          <TextLight size={13}>{data?.voucherNumber}</TextLight>
          <Div isFlex justify="space-between" margin="30px 0">
            <Div isFlex direction="column" justify="flex-start">
              {Object.entries(info)?.map(([key, value]) => (
                <TextLight size={13} mb={5} align="start">
                  <span style={{ fontWeight: weight.BOLD, marginRight: '3px' }}>
                    {key}
                  </span>
                  {value}
                </TextLight>
              ))}
            </Div>
            <Div>
              <Figure width="234px" height="115px">
                <img
                  src={LOGO_COOPCENTRAL}
                  alt="logo banco Coopcentral"
                />
              </Figure>
            </Div>
          </Div>
          <TextLight
            size={13}
            color={colors.error}
            weight={weight.BOLD}
            width={isDesktop ? '80%' : '100%'}
            mb={25}
          >
            Las transferencias realizadas a otras entidades Financieras después
            de las 3:40 p.m. Fin de semana o día festivo, serán abonados a las
            11:00 a.m. del día hábil siguiente
          </TextLight>
          <ErrorText isCenter text={errorMessage} />
          <Div isFlex justify="space-between">
            <Hollow
              style={{ width: `${isDesktop ? '330px' : '100%'}` }}
              isSubmit
              onColorType="primary"
              isLoading={isLoading}
              onClick={handleTransactionVoucher}
              size="14px"
              padding="7px 0"
            >
              Guardar comprobante
            </Hollow>
            {locationInclude ? (
              <Hollow
                isSubmit
                style={{ width: `${isDesktop ? '330px' : '100%'}` }}
                size="14px"
                onColorType="white"
                color={colors.primary}
                padding="7px 0"
                onClick={handleClose}
              >
                Ir a mi perfil
              </Hollow>
            ) : (
              <Hollow
                style={{ width: `${isDesktop ? '330px' : '100%'}` }}
                href={routes.profile.route}
                size="14px"
                onColorType="white"
                color={colors.primary}
                padding="7px 0"
              >
                Ir a mi perfil
              </Hollow>
            )}
          </Div>
        </Div>
      </DialogContent>
    </Dialog>
  );
};
