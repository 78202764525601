export const projectStateType = {
  ALL: 'ALL',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  POSTPONED: 'POSTPONED',
  MONEY_RETURNED: 'MONEY_RETURNED',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  MONEY_GIVE: 'MONEY_GIVE',
  ADVERTISING_CAMPAIGN: 'ADVERTISING_CAMPAIGN',
  PRE_MONEY_GIVE: 'PRE_MONEY_GIVE',
  PENDING_BOND: 'PENDING_BOND',
  FAIL_CLOSE: 'FAIL_CLOSE',
  LEGAL_REP_CONFIRMATION_ACCEPTED: 'LEGAL_REP_CONFIRMATION_ACCEPTED',
  LEGAL_REP_CONFIRMATION_REJECTED: 'LEGAL_REP_CONFIRMATION_REJECTED',
  PENDING_COMMISSION: 'PENDING_COMMISSION',
  ARREARS: 'ARREARS',
  COLLECTING: 'COLLECTING',
  WAITING_LEGAL_REP_CONFIRMATION: 'WAITING_LEGAL_REP_CONFIRMATION',
};

export const projectState = {
  ALL: 'ALL',
  OPEN: 'Abierto',
  CLOSED: 'Cerrado',
  POSTPONED: 'Pospuesto',
  MONEY_RETURNED: 'Devolución de dinero',
  PENDING_PAYMENT: 'Pendiente de pago',
  MONEY_GIVE: 'Dinero entregado',
  ADVERTISING_CAMPAIGN: 'Campaña publicitaria',
  PRE_MONEY_GIVE: 'Meta alcanzada',
  PENDING_BOND: 'Pendiente de bono',
  LEGAL_REP_CONFIRMATION_ACCEPTED: 'Pendiente por aprobación del RL',
  FAIL_CLOSE: 'Cerrado',
};

export const projectSectionsState = {
  OPEN: 'PROYECTO DISPONIBLE',
  CLOSED: 'PROYECTO CERRADO',
  PENDING_PAYMENT: 'PROYECTO FINANCIADO',
  ADVERTISING_CAMPAIGN: 'PRE-LANZAMIENTO',
  MONEY_GIVE: 'Dinero entregado',
  MONEY_RETURNED: 'Devolución de dinero',
  POSTPONED: 'Pospuesto',
  PRE_MONEY_GIVE: 'Meta alcanzada',
  PENDING_BOND: 'En proceso de emisión',
  PENDING_COMMISSION: 'Desembolso de recursos',
  WAITING_LEGAL_REP_CONFIRMATION: 'Pendiente de aprobación',
  COLLECTING: 'DESEMBOLSO DE RECURSOS',
  ARREARS: 'PROYECTO FINANCIADO',
  FAIL_CLOSE: 'NO ALCANZÓ LA META',
};

export const stateAfterOpening = [
  'OPEN',
  'CLOSED',
  'POSTPONED',
  'MONEY_RETURNED',
  'PENDING_PAYMENT',
  'MONEY_GIVE',
  'PRE_MONEY_GIVE',
  'PENDING_BOND',
  'FAIL_CLOSE',
  'PENDING_COMMISSION',
  'ARREARS',
  'COLLECTING',
];

export const statusInCollection = [
  'OPEN',
  'MONEY_GIVE',
  'PRE_MONEY_GIVE',
  'PENDING_BOND',
  'PENDING_COMMISSION',
];

export const statusInPayment = [
  'PENDING_PAYMENT',
  'ARREARS',
];
