module.exports.routes = {
  home: '/',
  login: '?open=login',
  loginCompany: '?open=company',
  acceptTerms: '?open=accept',
  register: '?open=register',
  terms: '?open=accept',
  recoverPassword: '?open=recover&type=password',
  recoverDevice: '?open=recover&type=device',
  project: {
    route: '/',
    params: '/:tag',
    guide: '/proyecto/guia/',
  },
  profile: {
    route: '/perfil',
    registry: '/perfil/registro/',
    registryParams: '/perfil/registro/:step?/:id?',
    changePassword: '/perfil/cambiar-contraseña',
    wallet: '/perfil/cartera',
    extract: '/perfil/cartera/reporte-de-movimientos',
    withDraw: '/perfil/retirar',
    information: '/perfil/informacion',
    invert: '/perfil/inversiones',
    savedProjects: '/perfil/proyectos-guardados/',
    notifications: '/perfil/notificaciones/',
  },
  profileCompany: {
    route: '/perfil/empresa/',
    bonds: '/perfil/empresa/emisiones/',
    inversionists: '/perfil/empresa/inversionistas/',
    information: '/perfil/empresa/informacion/',
    changePassword: '/perfil/empresa/cambiar-contraseña/',
    campaignBuilder: '/perfil/empresa/creador/',
    // updates: '/perfil/empresa/actualizaciones/',
    notifications: '/perfil/empresa/notificaciones/',
    transactionHistory: '/perfil/empresa/historia/',
    extract: '/perfil/empresa/reporte-de-movimientos',
    payInterbank: '?open=payInterbank',
    projectPreview: '/perfil/empresa/previsualizacion-proyecto',
  },
  message: {
    route: '/contactanos',
    params: '/:type?',
    contactUs: '/contactanos',
    team: '/trabaja-con-nosotros',
  },
  dataCompletion: {
    idVerification: '/verificacion-identidad',
    financialInfoCompletion: '/informacion-financiera',
  },
  financeProject: '/postula-tu-proyecto',
  aboutUs: '/acerca-de-nosotros',
  bulletin: '/boletines',
  correspondents: '/corresponsales',
  resources: '/informacion-productos-y-servicios',
  etic: '/etica',
  blog: '/blog',
  invert: '/invertir',
  registerPeru: '/registrar-peru',
  completeProfileCol: '/completar-perfil-colombia',
  completeProfilePer: '/completar-perfil-peru',
  frequentQuestions: '/preguntas-frecuentes',
  dataProcessingPolicy: '/politica-privacidad',
  termsAndConditions: '/T&C',
  inConstruction: '/en-construccion',
  financialConsumer: '/consumidor-financiero',
  consumer: '/consumidor',
  password: '/passwordTest',
  warranty: {
    route: '/garantia',
    params: '/:id',
    FNG: '/fondo-nacional-de-garantias',
  },
  variableIncome: {
    route: '/renta-variable',
    params: '/:id',
  },
};
