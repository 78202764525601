/* eslint-disable no-tabs */
/* eslint-disable no-shadow */
import styled, { createGlobalStyle, css } from 'styled-components';

export const PADDING = '30px';
export const HEADER_HEIGHT = '80px';
export const NAVBAR_HEIGHT = '70px';
export const FONT_SIZE = '16px';

export const colors = {
  primary: '#41B7BF',
  secondary: '#FFCD5C',
  tertiary: '#E96169',

  success: '#8CC63F',
  error: '#E96169',

  soyYo: '#6651FB',

  BLUE: '#3CA0A0',
  BLUE_2: '#7ACDD2',
  BLUE_3: '#A0DBDF',

  WHITE: '#FFFFFf',
  GRAY_1: '#606060',
  GRAY_2: '#828282',
  GRAY_3: '#B4B4B4',
  GRAY_4: '#EAEAEA',
  GRAY_5: '#E5E5E5',
  GRAY_6: '#707070',
  GRAY_7: '#F3F3F4',
  GRAY_8: '#D5D5D5',
  GRAY_9: '#4e4e4e',
  GRAY_10: '#E0E0E0',
  GRAY_11: '#B9B9B9',
  BLACK: '#000000',

  light: {
    primary: '#8DD4D9',
    tertiary: '#F2A0A5',
  },

  input: {
    active: '#B4B4B4',
    inactive: '#E0E0E0',
  },

  background: {
    GRAY_1: '#B4B4B4',
    GRAY_2: '#D6D6D6',
    GRAY_3: '#F2F3F1',
    GRAY_4: '#9E9E9E',
    GRAY_5: '#F1F1F1',
    WHITE: '#FFFFFf',
    WHITE_2: '#fefefe',
    WHITE_3: '#F2F2F2',
  },
};

export const weight = {
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  BLACK: 900,
};

export const GlobalStyle = createGlobalStyle`
    
    html {
        box-sizing: border-box;
        font-family: "Montserrat", sans-serif;
        font-size: ${FONT_SIZE};
    }

    input, select, button, a, p, h1, h2, h3, h4, h5, h6, span, label, textarea {
        font-family: "Montserrat", sans-serif;
        outline: none;
    }
    
    *, *::before, *::after {
        box-sizing: inherit;
    }
    
    ul, li, h1, h2, h3, p, button {
        margin: 0;
    }

    ul {
        list-style: none;
    }

    a{
        text-decoration: none;
    }

    button {
        background: transparent;
        border: 0;
        outline: none;
    }
    figure{
        margin: 0;
        img{
            width: 100%;
            height: 100%;
        }
    }

    video {
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
    }

    body {
        background: #fefefe;
        margin: 0;
    }
    div {
        outline: none;
    }
    iframe[owner="archetype"] {
        display: none;
        height: 0;
        width: 0;
        tabindex: -1;
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
}
`;

export const fill = css`
  height: calc(100% - (${(props) => props.fillHeight || 0}px));
  width: 100%;
  max-height: calc(100% - (${(props) => props.fillHeight || 0}px));
  min-height: calc(100% - (${(props) => props.fillHeight || 0}px));
  max-width: 100%;
  min-width: 100%;
`;

export const spaces = css`
  margin-top: ${(props) => props.mt && props.mt}px;
  margin-bottom: ${(props) => props.mb && props.mb}px;
  margin-right: ${(props) => props.mr && props.mr}px;
  margin-left: ${(props) => props.ml && props.ml}px;
  margin: ${(props) => props.margin && props.margin};

  padding-top: ${(props) => props.pt && props.pt}px;
  padding-bottom: ${(props) => props.pb && props.pb}px;
  padding-right: ${(props) => props.pr && props.pr}px;
  padding-left: ${(props) => props.pl && props.pl}px;
  padding: ${(props) => props.padding && props.padding};
  width: ${(props) => props.fullWidth && '100%'};
  height: ${(props) => props.fullHeight && '100%'};

  border-top: ${(props) => props.borderT && props.borderT};
  border-right: ${(props) => props.borderR && props.borderR};
  border-left: ${(props) => props.borderL && props.borderL};
  border-bottom: ${(props) => props.borderB && props.borderB};

  z-index: ${(props) => props.z && '10'};

  ${(props) => props.fluid
    && css`
      min-height: 100%;
      min-width: 100%;
    `}

  ${(props) => props.fill
    && css`
      ${fill}
    `}
`;

export const scroll = css`
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    background: ${(props) => props.backgroundColorBar && props.backgroundColorBar};
    width: ${(props) => props.width || 8}px;
    height: ${(props) => props.height || 8}px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.backgroundColorBar && props.backgroundColorBar};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.backgroundColorThumb && props.backgroundColorThumb};
    border-radius: ${(props) => props.radius || 20}px;
  }
`;

export const FlexCenter = css`
  ${spaces}
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: center;
`;

export const BoxFullHeight = styled.div`
  ${spaces}
  height: calc( 100vh - 80px);

  ${(props) => props.height
    && css`
      height: calc(
        100vh - (${HEADER_HEIGHT} + ${(props) => props.height || 0}px)
      );
    `};

  ${(props) => props.minHeight
    && css`
      min-height: calc(100vh - (80px + (${(props) => props.minHeight}px)));
      height: auto;
    `};

  ${(props) => props.isCenter
    && css`
      ${FlexCenter}
    `}
`;
export const Flex = css`
  ${spaces}
  display: flex;
  align-items: ${(props) => props.align || 'center'};
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justify || 'center'};
`;

export const ScrollBox = styled(BoxFullHeight)`
  ${scroll}
`;

export const Figure = styled.figure`
  ${FlexCenter}
  ${spaces}
    height: ${(props) => props.height && props.height};
  max-width: ${(props) => props.width && props.width};
  margin: ${(props) => props.margin && props.margin};
`;

export const Container = styled.div`
  min-height: ${(props) => props.height && props.height}%;
  width: ${(props) => props.width || '95'}%;
  margin: ${(props) => props.margin || '0 auto'};
  max-width: ${(props) => props.width || '90%'};
  background-color: ${(props) => props.backgroundColor && props.backgroundColor};
  padding: ${(props) => props.padding || '0'};

  ${(props) => props.sm
    && css`
      @media (min-width: 768px) {
        max-width: ${props.sm};
      }
    `}

  ${(props) => props.md
    && css`
      @media (min-width: 1500px) {
        max-width: ${props.md};
      }
    `}
    
    ${(props) => props.lg
    && css`
      @media (min-width: 1800px) {
        max-width: ${props.lg};
      }
    `}



    ${(props) => props.isCenter
    && css`
      ${FlexCenter}
    `}
    
    ${(props) => props.isFlex
    && css`
      ${Flex}
    `}
    ${spaces}
`;

export const Line = styled.div`
  ${spaces}
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  margin-top: ${(props) => (props.top ? props.top : '0')}px;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '0')}px;
  width: ${(props) => props.width && props.width};
  height: ${(props) => (props.height ? props.height : '1em')};
  border-bottom: ${(props) => props.thickness || '2px'} solid ${(props) => props.color || colors.GRAY_4};
`;

export const div = css`
  ${spaces}
  display: ${(props) => props.display || 'block'};
  width: ${(props) => props.width && props.width};
  position: ${(props) => props.position && props.position};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  height: ${(props) => props.height && props.height};
  min-height: ${(props) => props.minHeight && props.minHeight};
  background-color: ${(props) => props.backgroundColor && props.backgroundColor};
  border-radius: ${(props) => props.radius && props.radius};
  border: ${(props) => props.border && props.border};
  padding: ${(props) => props.p && props.p};
  padding: ${(props) => props.padding && props.padding};

  ${(props) => props.isScroll
    && css`
      ${scroll}
      &::-webkit-scrollbar {
        width: ${(props) => props.sWidth || 8}px;
        height: ${(props) => props.sHeight && 8}px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: ${(props) => props.sRadius || 20}px;
      }
    `}

  ${(props) => props.isCenter
    && css`
      ${FlexCenter}
    `}
    
    ${(props) => props.isOverflow
    && css`
      overflow: hidden;
    `}

    ${(props) => props.fill
    && css`
      ${fill}
    `}
    
    ${(props) => props.isFlex
    && css`
      ${Flex}
    `}
`;

export const Div = styled.div`
  ${div}
`;

export const Banner = styled.div`
  ${spaces}
  min-height: ${(props) => props.height && props.height};
  min-width: ${(props) => props.width && props.width};
  width: ${(props) => props.width && props.width};
  background-color: ${(props) => props.background && props.background};
  padding: ${(props) => props.padding && props.padding};
  margin: ${(props) => props.margin || '0 auto'};
  ${(props) => props.img
    && css`
      background-size: ${(props) => (props.isCover ? 'cover' : 'contain')};
      background-repeat: no-repeat;
      background-position-x: ${(props) => props.position && props.position};
      background-position-y: ${(props) => props.positiony && props.positiony};
      background-image: url(${(props) => props.img && props.img});
      background-position: ${(props) => props.isCenter && 'center center'};
    `}
`;

export const Row = styled.div`
  ${spaces}
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  justify-content: ${(props) => props.content || 'center'};
  align-items: ${(props) => props.alignItems};

  ${(props) => props.isCenter
    && css`
      ${FlexCenter}
    `}

  @media (min-width: 768px) {
    align-items: ${(props) => props.isAlign && 'center'};
    flex-direction: ${(props) => props.direction || 'row'};
    flex-wrap: ${(props) => (props.isWrap ? 'wrap' : 'nowrap')};
    justify-content: ${(props) => props.content || 'space-between'};

    align-items: ${(props) => props.vCenter && 'center'};
    justify-content: ${(props) => props.hCenter && 'center'};

    ${(props) => props.isCenter
      && css`
        ${FlexCenter}
      `}

    ${(props) => props.isContainer
      && css`
        max-width: ${(props) => props.width || '80%'};
        margin: ${(props) => props.top || 0}px auto
          ${(props) => props.bottom || 0}px auto;
      `}

        & > * {
      margin-right: ${(props) => props.allRight && props.allRight}px;
      margin-right: ${(props) => props.amr && props.amr}px;
      &:last-child {
        margin: 0;
      }
    }
  }
`;
export const Column = styled.div`
  flex: 100%;
  min-width: ${(props) => (props.minWidth ? '0' : 'none')};
  min-height: ${(props) => props.fullHeight && '100%'};
  ${spaces}

  @media (max-width: 767px) {
    margin-bottom: ${(props) => (props.bottom ? props.bottom : '25')}px;
  }

  @media (min-width: 768px) {
    flex: 0 0
      calc(
        ${(props) => (props.col ? (props.col / 12) * 100 : 100)}% -
          ${(props) => (props.space ? props.space : 0)}px
      );
    width: ${(props) => props.width && props.width};
  }
`;

export const NumberCircle = styled.div`
  width: ${({ width }) => (width || '25px')};
  height:${({ width }) => (width || '25px')};
  border-radius: 50%;
  background-color: ${({ bgcolor }) => (bgcolor || colors.primary)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ fontColor }) => (fontColor || colors.WHITE)};
  font-size: ${({ size, isDesktop }) => (size || isDesktop ? '18px' : '15px')}; 
  font-weight: ${({ fontWeight }) => (fontWeight || weight.SEMI_BOLD)}; 
 
`;
