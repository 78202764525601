import React, { useEffect } from 'react';
import { usePortalToken } from 'hooks';

import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyle } from 'globalStyle';
import { Switch, Route, useLocation as useLoc } from 'wouter';
import { Page, withProfile } from 'Layout';
import { routes } from 'routes';
import { withSuspense } from 'hoc/withSuspense';
import { Error } from 'page/Error';
import { userType } from 'types';
import { Loading } from 'components/Loading';
import Box from '@material-ui/core/Box';
import { withPrivateRoute } from 'hoc/withPrivateRoute';
import { SessionExpiresModal } from 'components/Modals';

// context
import { GeoInfoProvider } from 'context/GeoInfoContext';
import { useLocation } from 'hooks/useLocation';
import ButtonWhatsapp from 'components/ButtonWhatsapp';

const HomePage = React.lazy(() => import('page/Home'));
const FinanceProject = React.lazy(() => import('page/FinanceProject'));
const FrequentQuestions = React.lazy(() => import('page/FrequentQuestions'));
const Bulletin = React.lazy(() => import('page/Bulletin'));
const TermsAndConditions = React.lazy(() => import('page/TermsAndConditions'));
const DataProcessingPolicy = React.lazy(() => import('page/DataProcessingPolicy'));
const SendMessage = React.lazy(() => import('page/SendMessage'));
const Project = React.lazy(() => import('page/SingleProject'));
const InConstruction = React.lazy(() => import('page/InConstruction'));
const Resources = React.lazy(() => import('page/Resources'));
const Correspondents = React.lazy(() => import('page/Correspondents'));
const Etic = React.lazy(() => import('page/Etic'));
const Consumer = React.lazy(() => import('page/Consumer'));
const RegisterPeru = React.lazy(() => import('page/Register/RegisterPeru'));
const WhatDo = React.lazy(() => import('page/WhatDo'));
const Warranty = React.lazy(() => import('page/Warranty'));
const VariableIncome = React.lazy(() => import('page/VariableIncome'));
const FNGWarranty = React.lazy(() => import('page/FNGWarranty'));

// user
const Profile = React.lazy(() => import('page/Profile/User'));
const Information = React.lazy(() => import('page/Profile/User/Information'));
const TransactionHistory = React.lazy(() => import('page/Profile/User/TransactionHistory'));
const Notifications = React.lazy(() => import('page/components/Notification/Notifications'));
const Investments = React.lazy(() => import('page/Profile/User/Investments'));
const WithDraw = React.lazy(() => import('page/Profile/User/WithDraw'));
const SavedProjects = React.lazy(() => import('page/Profile/User/SavedProjects'));
const ChangePassword = React.lazy(() => import('page/Profile/User/ChangePassword'));
const CompleteProfileCol = React.lazy(() => import('page/Profile/User/CompleteProfileCol'));
const CompleteProfilePer = React.lazy(() => import('page/Profile/User/CompleteProfilePer'));

// perfil compañia
const ProfileCompany = React.lazy(() => import('page/Profile/Company/Profile'));
// const CampaignBuilder = React.lazy(() => import('page/Profile/Company/CampaignBuilder'));
const PreRegister = React.lazy(() => import('page/Profile/Company/PreRegister'));

const NotificationsCompany = React.lazy(() => import('page/components/Notification/Notifications'));
const TransactionHistoryCompnay = React.lazy(() => import('page/Profile/Company/TransactionHistory'));
const InformationCompany = React.lazy(() => import('page/Profile/Company/Information'));
const Inversionists = React.lazy(() => import('page/Profile/Company/Inversionists'));
const CompanyBonds = React.lazy(() => import('page/Profile/Company/Bonds'));
const ProjectPreview = React.lazy(() => import('page/Profile/Company/ProjectPreview'));
const IdentityVerification = React.lazy(() => import('page/IdentityVerification'));
const FinancialInfoCompletion = React.lazy(() => import('page/FinancialInfoCompletion'));

export default function App() {
  const { isLoadingToken } = usePortalToken();
  const { location, setLocation } = useLocation();
  const [currentLocation] = useLoc();

  useEffect(() => {
    setLocation({
      previous: location.current,
      current: currentLocation,
    });
  }, [currentLocation]);

  if (isLoadingToken) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Loading />
      </Box>
    );
  }

  return (
    <div>
      <GlobalStyle />
      <SessionExpiresModal />
      <ButtonWhatsapp />
      <HelmetProvider>
        <GeoInfoProvider>
          <Switch>
            <Page
              component={withSuspense(InConstruction)}
              path="/en-construccion"
            />
            <Page
              path={routes.home}
              description="Invierte de manera colaborativa en proyectos innovadores a través de Bloom, la plataforma líder en crowdfunding de inversión, vigilada por la Superintendencia Financiera.  Descubre oportunidades de inversión  apoyando empresas que necesiten financiación. ¡Comienza a construir un futuro financiero sólido hoy mismo con Bloom!"
              component={withSuspense(HomePage)}
            />
            <Page component={withSuspense(HomePage)} path={routes.invert} />
            <Page
              title="Financia tu proyecto"
              description="En Bloom, la oportunidad de transformar tu visión en realidad comienza aquí. Postula tu proyecto y únete a la comunidad de inversión colaborativa a través del crowdfunding. Descubre cómo podemos ayudarte a financiar tus ideas innovadoras y contribuir a un futuro financiero más colaborativo. ¡Empieza a dar vida a tu proyecto hoy mismo con Bloom!"
              component={withSuspense(FinanceProject)}
              path={routes.financeProject}
            />
            <Page
              title="Registrar Perú"
              component={withSuspense(RegisterPeru)}
              path={routes.registerPeru}
            />
            <Page
              title="Completar Perfil"
              noFooter
              component={withProfile(CompleteProfileCol, [userType.USER])}
              path={routes.completeProfileCol}
            />
            <Page
              title="Completar Perfil"
              component={withProfile(CompleteProfilePer, [userType.USER])}
              path={routes.completeProfilePer}
            />
            <Page
              title="Términos y condiciones"
              component={withSuspense(TermsAndConditions)}
              path={routes.termsAndConditions}
            />
            <Page
              title="Política de tratamiento de datos"
              component={withSuspense(DataProcessingPolicy)}
              path={routes.dataProcessingPolicy}
            />
            <Page
              title="Contáctanos"
              component={withSuspense(SendMessage)}
              path={routes.message.contactUs}
              description="¿Tienes preguntas sobre inversiones colaborativas a través del crowdfunding? ¡Estamos aquí para ayudarte! Ponte en contacto con nosotros en la sección 'Contáctanos' de Bloom. Nuestro equipo está listo para responder a cualquier consulta y brindarte la asistencia que necesitas. Explora cómo puedes ser parte de un futuro financiero colaborativo con Bloom."
            />
            <Page
              title="Empleos"
              component={withSuspense(SendMessage)}
              path={routes.message.team}
              description="¿Interesado en unirse a nuestro equipo y ser parte de la revolución en inversión colaborativa y crowdfunding? ¡Contáctanos a través de nuestro formulario y descubre cómo puedes contribuir a un futuro financiero y sólido con Bloom!"
            />
            <Page
              title="Quiénes Somos"
              component={withSuspense(WhatDo)}
              path={routes.aboutUs}
              description="En Bloom, nuestra misión es fomentar la inversión colaborativa a través del crowdfunding. Explora nuestra misión, visión y valores, junto con nuestros aliados que comparten nuestra idea de crear  un futuro financiero colaborativo"
            />
            <Page
              noFooter
              noNavBar
              title="Consumidor finanicero"
              component={withSuspense(InConstruction)}
              path={routes.financialConsumer}
            />
            <Page
              title="Preguntas frecuentes"
              component={withSuspense(FrequentQuestions)}
              path={routes.frequentQuestions}
              description="Encuentra respuestas a tus preguntas sobre inversiones colaborativas a través del crowdfunding en nuestra sección de 'Preguntas Frecuentes'. Explora cómo funciona Bloom, las oportunidades de inversión y todo lo que necesitas para comenzar tu viaje hacia una inversión colaborativa sólida, segura y con una gran diversidad de proyectos."
            />
            <Page
              title="Preguntas frecuentes"
              component={withSuspense(Bulletin)}
              path={routes.bulletin}
            />
            <Page
              title="Recursos"
              component={withSuspense(Resources)}
              path={routes.resources}
              description="Explora nuestra sección de Información de Productos y Servicios para conocer nuestras políticas legales y guías informativas. Además, si alguna vez necesitas asistencia o deseas presentar quejas, estamos aquí para ayudarte. ¡Descubre cómo hacer que tu inversión colaborativa sea segura y ética en Bloom!"
            />
            <Page
              title="Nuestros corresponsales"
              component={withSuspense(Correspondents)}
              path={routes.correspondents}
              description="Explora nuestra sección de Información de Productos y Servicios para conocer nuestras políticas legales y guías informativas. Además, si alguna vez necesitas asistencia o deseas presentar quejas, estamos aquí para ayudarte. ¡Descubre cómo hacer que tu inversión colaborativa sea segura y ética en Bloom!"
            />
            <Page
              title="Consumidor"
              component={withSuspense(Consumer)}
              path={routes.consumer}
            />
            <Page
              title="Ética"
              component={withSuspense(Etic)}
              path={routes.etic}
            />
            <Page
              title="Fondo Nacional de Garantías"
              component={withSuspense(FNGWarranty)}
              path={routes.warranty.FNG}
              description="Bloom en alianza con el Fondo nacional de Garantias S.A. (FNG) ofrece alternativas para el acceso al financiaciamiento de proyectos a empresas y emprendimientos. Blooom como sociedad de financiamiento colaborativo, conecta a las personas que buscan oportunidades de inversión con las empresas que buscan financiamiento."
            />
            <Page
              title="Garantía"
              component={withSuspense(Warranty)}
              path={`${routes.warranty.route}${routes.warranty.params}`}
              description="Descubre nuestras garantías de inversión colaborativa en Bloom, la plataforma líder de crowdfunding. En esta sección te explicamos cómo protegemos tus inversiones y fomentamos un entorno financiero colaborativo seguro. Obtén la tranquilidad que necesitas mientras exploras oportunidades de inversión colaborativa."
            />
            <Page
              title="Renta variable"
              component={withSuspense(VariableIncome)}
              path={`${routes.variableIncome.route}${routes.variableIncome.params}`}
              description=""
            />

            {/* Inversionista */}
            <Page
              noFooter
              component={withProfile(Profile, [userType.USER])}
              path={routes.profile.route}
              title="Mi perfil"
              description="En la sección 'Mi Perfil' de Bloom, gestiona tus inversiones colaborativas de manera eficiente, realiza un seguimiento de tus inversiones actuales y edita tu información según sea necesario. También puedes retirar o recargar tu saldo para mantener tu participación en un futuro financiero colaborativo dentro de Bloom crowfunding."
            />
            <Page
              noFooter
              component={withProfile(Information, [userType.USER])}
              path={routes.profile.information}
              title="Mi información"
            />
            <Page
              noFooter
              component={withProfile(TransactionHistory, [userType.USER])}
              path={routes.profile.wallet}
              title="Mi cartera"
            />

            <Page
              noFooter
              title="Verificación de identidad"
              path={routes.dataCompletion.idVerification}
              component={withSuspense(IdentityVerification)}
            />
            <Page
              noFooter
              title="Completar información financiera"
              path={routes.dataCompletion.financialInfoCompletion}
              component={withSuspense(withPrivateRoute(FinancialInfoCompletion))}
            />
            <Page
              noFooter
              component={withProfile(Notifications, [userType.USER])}
              path={routes.profile.notifications}
              title="Notificaciones"
            />
            <Page
              noFooter
              component={withProfile(Investments, [userType.USER])}
              path={routes.profile.invert}
              title="Mis inversiones"
            />
            <Page
              noFooter
              component={withProfile(WithDraw, [userType.USER])}
              path={routes.profile.withDraw}
              title="Retirar dinero"
            />
            <Page
              noFooter
              component={withProfile(SavedProjects, [userType.USER])}
              path={routes.profile.savedProjects}
              title="Proyectos guardados"
            />
            <Page
              title="Cambiar contraseña"
              component={withProfile(ChangePassword, [userType.USER])}
              path={routes.profile.changePassword}
            />

            {/* Empresa */}
            <Page
              title="Mi empresa"
              noFooter
              component={withProfile(ProfileCompany, [userType.COMPANY])}
              path={routes.profileCompany.route}
            />
            <Page
              title="Creador de campaña"
              noFooter
              component={withProfile(PreRegister, [userType.COMPANY])}
              path={routes.profileCompany.campaignBuilder}
            />
            {/* <Page
              title="Mis actualizaciones"
              noFooter
              component={withProfile(Updates, [userType.COMPANY])}
              path={routes.profileCompany.updates}
            /> */}
            <Page
              title="Mi historial"
              noFooter
              component={withProfile(TransactionHistoryCompnay, [
                userType.COMPANY,
              ])}
              path={routes.profileCompany.transactionHistory}
            />

            <Page
              title="Mis inversionistas"
              noFooter
              component={withProfile(Inversionists, [userType.COMPANY])}
              path={routes.profileCompany.inversionists}
            />
            <Page
              title="Mi Informacion"
              noFooter
              component={withProfile(InformationCompany, [userType.COMPANY])}
              path={routes.profileCompany.information}
            />
            <Page
              title="Mis notificaciones"
              noFooter
              component={withProfile(NotificationsCompany, [userType.COMPANY])}
              path={routes.profileCompany.notifications}
            />
            <Page
              title="Mis emisiones"
              noFooter
              component={withProfile(CompanyBonds, [userType.COMPANY])}
              path={routes.profileCompany.bonds}
            />
            <Page
              title="Previsualizacion de proyecto"
              noFooter
              component={withSuspense(withPrivateRoute(ProjectPreview))}
              path={routes.profileCompany.projectPreview}

            />
            <Page
              title="Proyecto"
              component={withSuspense(Project)}
              path="/:tag"
            />
            <Route component={Error} />
          </Switch>
        </GeoInfoProvider>
      </HelmetProvider>

    </div>
  );
}
