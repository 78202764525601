/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Div } from 'globalStyle';
// import styled, { css } from 'styled-components';
import { StyledSelect } from './styled';
import { Label } from './Label';
import { ErrorText } from './ErrorText';

export const Select = ({
  mb,
  required,
  label,
  text,
  name,
  errors,
  register,
  isCenter,
  placeholder = 'Selecciona una',
  errorText,
  children,
  width,
  number,
  icon,
  ...rest
}) => (
  <Div mb={mb ?? 18} width={width} position={icon && 'relative'} {...rest}>
    <Label title={label} subTitle={text} required={required} number={number} {...rest} />
    {icon}
    <StyledSelect
      name={name}
      appearance={icon && 'none'}
      isError={errors[name] && errors[name]}
      ref={register({
        required: required ? 'Este campo es obligatorio' : false,
      })}
      {...rest}
    >
      <option disabled selected value="">
        {placeholder}
      </option>
      {children}
    </StyledSelect>
    <ErrorText errors={errors} name={name} />
  </Div>
);
