import { useState, useEffect, useCallback } from 'react';
import { userInvestmentsService } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useMountedState } from 'react-use';
import { updateInvestments } from 'store/investments';
import { useUser } from '../useUser';
import { useCancelToken } from '../useCancelToken';

export const useInvestmentsCache = () => {
  const isMounted = useMountedState();
  const dispatch = useDispatch();
  const investmentsCache = useSelector(
    (store) => store.investments.investments,
  );

  const setInvestmentsInfo = useCallback((investmentsInfo) => {
    if (isMounted()) {
      dispatch(updateInvestments(investmentsInfo));
    }
  }, []);

  return {
    investmentsCache: investmentsCache || {},
    setInvestmentsInfo,
  };
};

export const useInvestmentsUser = () => {
  const { isLogin, user } = useUser();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({});
  const { isMounted, logCallback } = useCancelToken();
  const { investmentsCache, setInvestmentsInfo } = useInvestmentsCache();

  const forceUpdate = () => {
    setLoading(true);
    if (isLogin) {
      userInvestmentsService()
        .then(({ data }) => {
          if (isMounted) {
            setLoading(false);
            /* eslint-disable-next-line no-param-reassign */
            data.investment = data.investment.map((v) => {
              if (
                v.projectItem.projectStatus === 'OPEN'
                && v.projectItem.maxAmount === v.projectItem.currentAmount
              ) {
                const newValue = { ...v };
                newValue.projectItem.projectStatus = 'COLLECTING';
                return newValue;
              }
              return v;
            });
            setInvestmentsInfo(data);
            setValue(data);
          }
        })
        .catch((err) => {
          logCallback(err, () => {
            setLoading(false);
          });
        });
    }
  };

  const fetch = () => {
    setLoading(true);
    if (Object.keys(investmentsCache)?.length === 0) {
      forceUpdate();
    } else {
      setValue(investmentsCache);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  return {
    token: user?.token || null,
    investments: value?.investment || [],
    bonds: value?.bonds || [],
    bankAccount: value?.bankAccount || '',
    isLoadinginvestmentsUser: loading,
    fetch: forceUpdate,
  };
};
