import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import { config } from 'config';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
import authReducer from 'store/auth';
import regionsReducer from 'store/regions';
import locationReducer from 'store/location';
import portalTokenReducer from 'store/portalToken';
import popupReducer from 'store/popup';
import walletReducer from 'store/wallet';
import notificationsReducer from 'store/notification';
import investmentsReducer from 'store/investments';
import userInfoReducer from 'store/userInfo';
import projectsReducer from 'store/projects';
import bccUserDropdowns from 'store/bccUserDropdowns';
import favoriteAccountsReducer from 'store/favoriteAccounts';
import bondsReducer from 'store/bonds';
import investorsReducer from 'store/investors';
import projectsCompanyReducer from 'store/projectsCompany';
import projectReducer from './projectPreview';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'regions', 'portalToken', 'popup'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  popup: popupReducer,
  regions: regionsReducer,
  portalToken: portalTokenReducer,
  location: locationReducer,
  wallet: walletReducer,
  notifications: notificationsReducer,
  investments: investmentsReducer,
  userInfo: userInfoReducer,
  projects: projectsReducer,
  bccDropdowns: bccUserDropdowns,
  favoriteAccounts: favoriteAccountsReducer,
  bonds: bondsReducer,
  investors: investorsReducer,
  projectsCompany: projectsCompanyReducer,
  projectPreview: projectReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  config.dev && composeWithDevTools(applyMiddleware(thunk)),
);
const persistor = persistStore(store);

export { store, persistor };
