export const REGLAMENTO_BLOOM = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/REGLAMENTO+BLOOM.pdf';
export const TERMINOS_CONDICIONES_BLOOM = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/TE%CC%81RMINOS+Y+CONDICIONES+BLOOM+-+COLOMBIA.pdf';
export const POLITICA_TRATAMIENTO_AND_PROTECCION_DATOS_PERSONALES_BLOOM = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/POL%C3%8DTICA+DE+TRATAMIENTO+Y+PROTECCI%C3%93N+DE+DATOS+PERSONALES+-+COLOMBIA.pdf';
export const GUIA_CAMPAÑA = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/guia_campana.pdf';
export const GUIA_ENVIO_DOCUMENTACION = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/Gu%C3%ADa_Documentaci%C3%B3n.pdf';
export const LOGO_BLOOM = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/Logos+de+Bloom+Crowdfunding.zip';
export const MANUAL_BLOOM = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/Manual+de+Marca_+BloomCrowdfunding.pdf';
export const GUIA_INVERSION = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/Gu%C3%ADa_inversión.pdf';
export const CODIGO_BUEN_GOBIERNO = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/C%C3%93DIGO+DE+BUEN+GOBIERNO+BLOOM.docx.pdf';

export const BCC_REGLAMENTO_DEPOSITO_ORDINARIO = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/REGLAMENTO+DEP%C3%93SITO+ORDINARIO.pdf';
export const BCC_REGLAMENTO_DEPOSITO_BAJO_MONTO = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/REGLAMENTO+DEP%C3%93SITO+DE+BAJO+MONTO.pdf';

export const ANEXO_OPERATIVO_INVERSIONISTA = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/ANEXO+OPERATIVO+DEL+INVERSIONISTA.pdf';
export const ANEXO_OPERATIVO_EMPRENDEDOR = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/ANEXO+OPERATIVO+DEL+EMPRENDEDOR.pdf';
export const LEY_2300_DE_2023 = 'https://s3.us-east-2.amazonaws.com/cmint.pro/bloom-documentos/ley_2300_de_2023_dejen_de_fregar.pdf';
export const ANEXO_TRANSFERENCIA_DE_LA_PROPIEDAD = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/PROCEDIMIENTO+PARA+MEDIDAS+CAUTELARES+U+OTRAS+MEDIDAS+QUE+LIMITEN+LA+TRANSFERENCIA+DE+LA+PROPIEDAD_230120.docx.pdf';
export const ANEXO_ESTADOS_FINANCIEROS = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/BLOOM+CROWDFUNDING+SA+-+DICTAMINADOS.pdf';
export const ANEXO_ESTADOS_FINANCIEROS_2023 = 'https://bloom-public-documents.s3.us-east-2.amazonaws.com/EEFF+2023+-+BLOOM+CROWDFUNDING+SA.pdf';

export const LOGO_SUPERINTENDENCIA_BLANCO = 'https://s3.us-east-2.amazonaws.com/cmint.pro/BloomImages/Logo+Superintendencia+Blanco+SVG.svg';
export const LOGO_SUPERINTENDENCIA_NEGRO = 'https://s3.us-east-2.amazonaws.com/cmint.pro/BloomImages/Logo+SUperintendencia+Negro+svg.svg';

export const WIXSITE = 'https://german924.wixsite.com/website';
export const LOGO_COOPCENTRAL = 'https://s3.us-east-2.amazonaws.com/cmint.pro/BloomImages/Logo+Coopcentral+Corresponsal+Color.svg';

export const VIDEOABOUTBLOOM = 'https://www.youtube.com/watch?v=stDM21o9uak&ab_channel=InversionesBloom';
