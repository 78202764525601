import styled from 'styled-components';
import { colors } from 'globalStyle';

export const HamburguerMenu = styled.button`
    background: ${colors.primary};
    border-radius: 30px 0 0 30px;
    width: 110px;
    cursor: pointer;
    height: 56px;
    position:relative;
    margin-left: auto;
    border:none;
    padding-right: 25px;

    display:flex;
    justify-content: flex-end;
    align-items: center;

    position: relative;

    z-index: 10;
    transition: 0.5s ease-in-out;
    left: ${({ isOpen }) => (isOpen ? '-100%' : '0')};
    &::before {
        content: "Menú";
        color: white;
        font-size: 1.2em;
        font-weight: 700;
        position: absolute;
    }
   
    &::before {
        top:17px;
    }

    
`;

export const Content = styled.div`
     @media (min-width: 1960px) {
               margin: 0 auto;
                width: 1920px;
        }

  width: 100%;
`;
