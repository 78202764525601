import img1 from 'assets/ods/1.svg';
import img2 from 'assets/ods/2.svg';
import img3 from 'assets/ods/3.svg';
import img4 from 'assets/ods/4.svg';
import img5 from 'assets/ods/5.svg';
import img6 from 'assets/ods/6.svg';
import img7 from 'assets/ods/7.svg';
import img8 from 'assets/ods/8.svg';
import img9 from 'assets/ods/9.svg';
import img10 from 'assets/ods/10.svg';
import img11 from 'assets/ods/11.svg';
import img12 from 'assets/ods/12.svg';
import img13 from 'assets/ods/13.svg';
import img14 from 'assets/ods/14.svg';
import img15 from 'assets/ods/15.svg';
import img16 from 'assets/ods/16.svg';
import img17 from 'assets/ods/17.svg';

export const socialImpactType = [
  {
    id: 1,
    value: 'Fin de la pobreza (Hambruna, educacion y salud)',
    description: 'Poner fin a la pobreza en todas sus formas en todo el mundo',
    image: img1,
  },
  {
    id: 2,
    value: 'Hambre cero',
    description: 'Poner fin al hambre, lograr la seguridad alimentaria y la mejora de la nutrición y promover la agricultura sostenible',
    image: img2,
  },
  {
    id: 3,
    value: 'Salud y bienestar',
    description: 'Garantizar una vida sana y promover el bienestar para todos en todas las edades',
    image: img3,
  },
  {
    id: 4,
    value: 'Educación de calidad',
    description: 'Garantizar una educación inclusiva, equitativa y de calidad y promover oportunidades de aprendizaje durante toda la vida para todos ',
    image: img4,
  },
  {
    id: 5,
    value: 'Igualdad de género',
    description: 'Lograr la igualdad entre los géneros y empoderar a todas las mujeres y las niñas',
    image: img5,
  },
  {
    id: 6,
    value: 'Agua limpia y saneamiento',
    description: 'Garantizar la disponibilidad de agua y su gestión sostenible y el saneamiento para todos',
    image: img6,
  },
  {
    id: 7,
    value: 'Energía asequible y no contaminante',
    description: 'Garantizar el acceso a una energía asequible, segura, sostenible y moderna para todos',
    image: img7,
  },
  {
    id: 8,
    value: 'Trabajo decente y crecimiento económico',
    description: 'Promover el crecimiento económico sostenido, inclusivo y sostenible, el empleo pleno y productivo y el trabajo decente para todos',
    image: img8,
  },
  {
    id: 9,
    value: 'Industria, innovación e infraestructura',
    description: 'Construir infraestructuras resilientes, promover la industrialización inclusiva y sostenible, y fomentar la innovación',
    image: img9,
  },
  {
    id: 10,
    value: 'Reducción de las desigualdades',
    description: 'Reducir la desigualdad en y entre los países',
    image: img10,
  },
  {
    id: 11,
    value: 'Ciudades y comunidades sostenibles',
    description: 'Lograr que las ciudades y los asentamientos humanos sean inclusivos, seguros, resilientes y sostenibles',
    image: img11,
  },
  {
    id: 12,
    value: 'Producción y consumo',
    description: 'Garantizar modalidades de consumo y producción sostenibles',
    image: img12,
  },
  {
    id: 13,
    value: 'Acción por el clima',
    description: 'Adoptar medidas urgentes para combatir el cambio climático y sus efectos',
    image: img13,
  },
  {
    id: 14,
    value: 'Vida submarina',
    description: 'Conservar y utilizar sosteniblemente los océanos, los mares y los recursos marinos para el desarrollo sostenible',
    image: img14,
  },
  {
    id: 15,
    value: 'Vida de ecosistemas terrestres',
    description: 'Proteger, restablecer y promover el uso sostenible de los ecosistemas terrestres, gestionar sosteniblemente los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras y detener la pérdida de biodiversidad',
    image: img15,
  },
  {
    id: 16,
    value: 'Paz justicia e instituciones solidad arias',
    description: 'Promover sociedades pacíficas e inclusivas para el desarrollo sostenible, facilitar el acceso a la justicia para todos y construir a todos los niveles instituciones eficaces e inclusivas que rindan cuentas',
    image: img16,
  },
  {
    id: 17,
    value: 'Alianzas para lograr los objetivos',
    description: 'Fortalecer los medios de implementación y revitalizar la Alianza Mundial para el Desarrollo Sostenible',
    image: img17,
  },
  {
    id: 17,
    value: 'Alianzas para lograr los objetivos',
    description: 'Fortalecer los medios de implementación y revitalizar la Alianza Mundial para el Desarrollo Sostenible',
    image: img17,
  },
  {
    id: 18,
    value: 'Otra',
  },
];

export const findSocialImpactByID = (id) => socialImpactType
  .find((impact) => impact.id === parseInt(id, 10));

export const findSocialImpactByValue = (value) => socialImpactType
  .find((impact) => impact.value.toLocaleLowerCase() === value.toLocaleLowerCase());

export const filterSocialImpactByValue = (value) => socialImpactType
  .filter((impact) => impact.value.toLocaleLowerCase() === value.toLocaleLowerCase());
